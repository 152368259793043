<template>
	<el-dialog
		v-model="isShowDialog"
		width="650px"
		class="role-dialog-container"
		:title="dialogTitle"
		:before-close="close"
		:close-on-click-modal="false"
		append-to-body
		destroy-on-close
	>
		<div class="dialog-body" v-loading="isLoading">
			<el-form
				ref="ruleFormRef"
				label-width="150px"
				size="large"
				:model="formData"
				:rules="formRule"
			>
				<el-row>
					<el-col :span="18">
						<el-form-item label="角色名称" :prop="roleName">
							<el-input
								v-model="formData.roleName"
								placeholder="请输入角色名称"
								maxlength="20"
								show-word-limit
								clearable
							/>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="18">
						<el-form-item label="数据范围" prop="rolePermissions">
							<el-radio-group v-model="formData.rolePermissions" :disabled="isDisabled">
								<el-radio
									v-for="(item, index) in rolePermissionsOptions"
									:key="index"
									:label="item.code"
								>
									{{ item.name }}
								</el-radio>
							</el-radio-group>
						</el-form-item>
					</el-col>
				</el-row>
				<!-- <el-row v-if="dialogType === 'grant-menu'">
					<el-col :span="21">
						<el-form-item label="系统菜单" prop="menus">
							<el-radio-group
								v-model="menuTabActive"
								size="default"
								class="radio-box"
								@change="onChangeMenusTab"
							>
								<el-radio-button label="pc">FEP管理后台</el-radio-button>
								<el-radio-button label="mp">FEP微信小程序</el-radio-button>
							</el-radio-group>
							<div class="checkbox-box">
								<el-checkbox-group v-model="menuSelected">
									<div v-for="(menu, mIndex) in menuList" :key="mIndex" class="checkbox-item">
										<div class="item-parent">
											<el-checkbox :label="menu.code">{{ menu.name }}</el-checkbox>
										</div>
										<div class="item-children" v-if="menu.btnAuth.length > 0">
											<div
												v-for="(oneChildren, oIndex) in menu.btnAuth"
												class="children-item"
												:key="oIndex"
												:class="oneChildren.btnAuth.length > 0 ? '' : 'no-children-two'"
											>
												<div class="children-one">
													<el-row :gutter="10">
														<el-col :span="6">
															<el-checkbox :label="oneChildren.code">
																{{ oneChildren.name }}
															</el-checkbox>
														</el-col>
													</el-row>
												</div>
												<div class="children-two" v-if="oneChildren.btnAuth.length > 0">
													<el-row :gutter="10">
														<el-col
															v-for="(twoChildren, tIndex) in oneChildren.btnAuth"
															:key="tIndex"
															:span="6"
														>
															<el-checkbox :label="twoChildren.code">
																{{ twoChildren.name }}
															</el-checkbox>
														</el-col>
													</el-row>
												</div>
											</div>
										</div>
									</div>
								</el-checkbox-group>
							</div>
						</el-form-item>
					</el-col>
				</el-row> -->
			</el-form>
		</div>
		<template #footer>
			<div class="dialog-footer">
				<el-button type="primary" :disabled="isDisabled" @click="onSubmit">保 存</el-button>
				<el-button :disabled="isDisabled" @click="onClose">关 闭</el-button>
			</div>
		</template>
	</el-dialog>
</template>

<script setup>
import { defineExpose, inject, reactive, getCurrentInstance, ref } from 'vue'

const formInstance = getCurrentInstance()

const $messageBox = inject('$messageBox')

const isShowDialog = ref(false)
const isDisabled = ref(false)
const isLoading = ref(false)
const formData = reactive({})
const formRule = {}
const rolePermissionsOptions = [
	{ name: '个人', code: 10 },
	{ name: '公司', code: 20 },
]

let formType = 'create'

function create() {
	formType = 'create'
	isShowDialog.value = true
}

function update() {
	formType = 'update'
	isShowDialog.value = true
}

function deleteRole() {
	$messageBox
		.confirm('确定将删除该条数据？', '提示', {
			type: 'warning',
			confirmButtonText: '确定',
			cancelButtonText: '取消',
		})
		.then(() => {})
		.catch(() => {})
}

function setAuth() {
	formType = 'setAuth'
	isShowDialog.value = true
}

function onSubmit() {
	onClose()
}

function onClose() {
	isShowDialog.value = false
}

defineExpose({ create, update, deleteRole, setAuth })
</script>

<style lang="less" scoped>
.role-dialog-container {
	width: 100%;
	height: 100%;
	overflow: hidden;
	.dialog-body {
		width: 100%;
		overflow: hidden;
		.radio-box {
			width: 100%;
			overflow: hidden;
		}
		.content-box {
			width: 100%;
			overflow: hidden;
		}
		.checkbox-box {
			width: 100%;
			margin-top: 10px;
			overflow: hidden;
			.checkbox-item {
				width: 100%;
				overflow: hidden;
				.item-children {
					width: 100%;
					overflow: hidden;
					.no-children-two {
						display: inline-block;
					}
					.children-one {
						margin-left: 25px;
					}
					.children-two {
						margin-left: 50px;
					}
				}
			}
		}
	}
}
</style>
